<template>
  <Base cycletype="Forecast"></Base>
</template>

<script>
import Base from './Base.vue';
export default {
  name: 'commentary_forecast_cycle',
  // props: ['props'],
  data: function () {
    return {
    }
  },
  components: {
    Base
  },
  created() {
    this.refreshData();
  },
  activated() {
    this.refreshData();
  },
  methods: {
    refreshData() {
    },
  },
  watch: {
  }
}
</script>
